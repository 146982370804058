.content {
  position: relative;
  z-index: 1;

  &_inner {
    max-width: 620px;
    margin: 0 0 0 auto;
    padding: 20px 70px 50px 50px;
    color: black;

    @include mobileXl {
      max-width: 100%;
      margin: 0 auto;
    }

    @media all and (max-width: 570px) {
      padding: 20px 30px 50px 30px;
    }
  }

  &_menu {
    display: flex;
    align-items: center;
    position: sticky;
    top: -1px;
    right: 0;
    min-height: 50px;
    z-index: 1;
    background: white;

    @include mobileXl {
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px 0;
    }

    @media all and (max-width: 400px) {
      justify-content: space-between;
    }
  }

  &_menu-link {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: 30px;
    font-size: 14px;
    color: black;
    text-decoration: none;
    letter-spacing: 1.5;
    white-space: nowrap;

    @include mobileXl {
      padding: 10px 0;
    }

    @media all and (max-width: 390px) {
      margin-right: 10px;
      font-size: 13px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 0.5px;
    }
  }

  &_menu-icon {
    height: 15px;
    margin-right: 5px;
  }

  &_menu-icon-li {
    height: 19px;
  }

  &_title {
    position: fixed;
    top: 20px;
    left: 50px;
    padding-right: 10px;
    background-color: rgba(white, 0.85);
    font-size: 32px;
    font-weight: 400;
    line-height: 1.5;
    color: black;

    @include mobileXl {
      position: static;
      margin-top: 30px;
      background: none;
      text-align: center;
    }

    @media all and (max-width: 400px) {
      font-size: 27px;
    }
  }

  &_face {
    width: 100%;
    max-width: 250px;
    margin: 30px auto 0 auto;
    display: none;
    border: 1px black dashed;
    border-radius: 50%;

    @include mobileXl {
      display: block;
    }

    @media all and (max-width: 400px) {
      max-width: 170px;
    }
  }

  &_title-description {
    font-size: 16px;
  }

  &_description {
    margin-top: 30px;
    font-size: 16px;
    letter-spacing: 1.05;
    line-height: 1.87;
  }

  &_accent {
    display: inline-block;
    padding: 0 4px 1px 4px;
    line-height: 1.25;
    color: white;
    font-weight: 300;
    background: rgba(30, 30, 30, 1);
  }

  &_emoji {
    display: inline-block;
    position: relative;
    top: 2px;
    left: -3px;
    margin-right: 3px;
    font-size: 50px;
    font-family: "Honk", system-ui;
    line-height: 0;
  }

  &_accent-secondary {
    display: block;
    margin: 0 14px 10px 0;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
  }

  &_list-wrapper {
    margin-top: 55px;
  }

  &_list-item {
    position: relative;
    margin-bottom: 55px;
    padding-bottom: 20px;
    border-bottom: 1px black dashed;
    font-size: 16px;
    letter-spacing: 1.05;
    line-height: 1.87;

    &:last-child {
      border-bottom: none;
    }
  }
}
