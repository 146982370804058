.iam {
  width: calc(100vw - 650px - 90px);
  height: 80vh;
  position: fixed;
  left: 0%;
  bottom: 0;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  transition: transform 0.5s linear;
  transform: none !important;

  $lineColor: black;
  $strokeWidthIncrement: 1.5;

  
  @include mobileXl {
    display: none;
  }

  &-2 {
    left: auto;
    right: -25%;
  }

  svg {
    width: calc((100vw - 650px - 90px) / 1.2);
    position: absolute;
    left: 9%;
    bottom: 0;

    @media all and (max-width: 1320px) {
      width: calc((100vw - 650px - 90px) / 1.15);
      left: 8%;
    }
  }

  .st0 {
    fill:none;
    stroke: $lineColor;
    stroke-linecap:round;
    stroke-miterlimit:10;
    stroke-width:  0.9 * $strokeWidthIncrement;
  }

  .part-3,
  .part-4,
  .part-5 {
    .st1 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.4 * $strokeWidthIncrement;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }

  .part-1 {
    .st1 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.4 * $strokeWidthIncrement;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
    
    .st2 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.5 * $strokeWidthIncrement;
      stroke-miterlimit:10;
    }
  }

  .part-2 {
    .st1 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-miterlimit:10;
    }
    
    .st2 {
      opacity:0.43;
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-miterlimit:10;
      enable-background:new;
    }

    .st3 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.4 * $strokeWidthIncrement;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }

  .part-4 {
    .st2 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-miterlimit:10;
    }
  }

  .part-5 {
    .st2 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }

    .st3 {
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-miterlimit:10;
    }

    .st4 {
      fill:none;
      stroke: $lineColor;
      stroke-width:0 .25 * $strokeWidthIncrement;
      stroke-miterlimit:10;
    }
  }
  
  .part-6 {
    opacity: 0;

    .st1{
      fill:none;
      stroke: $lineColor;
      stroke-width: 0.7 * $strokeWidthIncrement;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }

    &.show {
      animation: showEyes 1.5s forwards;
    }
  }

  @keyframes showEyes {
    100% {
      opacity: 1;
    }
  }
}
