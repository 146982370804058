@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  font-family: Roboto, sans-serif;
  position: relative;
}

body:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

body.show:after {
  pointer-events: none;
  opacity: 0;
}

body:before {
  pointer-events: none;
  content: "";
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width <= 1100px) {
  body:before {
    display: none;
  }
}

* {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  margin: 0;
  padding: 0;
}

::selection {
  color: #000;
  background: #ff0;
}

a:focus {
  color: #000;
  background: #ff0;
  outline: none;
}

body * {
  animation-play-state: paused !important;
}

body.playAnimation * {
  animation-play-state: running !important;
}

.iam {
  transform-style: preserve-3d;
  transform-origin: 50%;
  width: calc(100vw - 740px);
  height: 80vh;
  transition: transform .5s linear;
  position: fixed;
  bottom: 0;
  left: 0%;
  transform: none !important;
}

@media (width <= 1100px) {
  .iam {
    display: none;
  }
}

.iam-2 {
  left: auto;
  right: -25%;
}

.iam svg {
  width: calc(83.3333vw - 616.667px);
  position: absolute;
  bottom: 0;
  left: 9%;
}

@media (width <= 1320px) {
  .iam svg {
    width: calc(86.9565vw - 643.478px);
    left: 8%;
  }
}

.iam .st0 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 1.35px;
}

.iam .part-3 .st1, .iam .part-4 .st1, .iam .part-5 .st1, .iam .part-1 .st1 {
  fill: none;
  stroke: #000;
  stroke-width: .6px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.iam .part-1 .st2 {
  fill: none;
  stroke: #000;
  stroke-width: .75px;
  stroke-miterlimit: 10;
}

.iam .part-2 .st1 {
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-miterlimit: 10;
}

.iam .part-2 .st2 {
  opacity: .43;
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-miterlimit: 10;
  enable-background: new;
}

.iam .part-2 .st3 {
  fill: none;
  stroke: #000;
  stroke-width: .6px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.iam .part-4 .st2 {
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-miterlimit: 10;
}

.iam .part-5 .st2 {
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.iam .part-5 .st3 {
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-miterlimit: 10;
}

.iam .part-5 .st4 {
  fill: none;
  stroke: #000;
  stroke-width: 0 .375;
  stroke-miterlimit: 10;
}

.iam .part-6 {
  opacity: 0;
}

.iam .part-6 .st1 {
  fill: none;
  stroke: #000;
  stroke-width: 1.05px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.iam .part-6.show {
  animation: 1.5s forwards showEyes;
}

@keyframes showEyes {
  100% {
    opacity: 1;
  }
}

.content {
  z-index: 1;
  position: relative;
}

.content_inner {
  color: #000;
  max-width: 620px;
  margin: 0 0 0 auto;
  padding: 20px 70px 50px 50px;
}

@media (width <= 1100px) {
  .content_inner {
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (width <= 570px) {
  .content_inner {
    padding: 20px 30px 50px;
  }
}

.content_menu {
  z-index: 1;
  background: #fff;
  align-items: center;
  min-height: 50px;
  display: flex;
  position: sticky;
  top: -1px;
  right: 0;
}

@media (width <= 1100px) {
  .content_menu {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }
}

@media (width <= 400px) {
  .content_menu {
    justify-content: space-between;
  }
}

.content_menu-link {
  color: #000;
  letter-spacing: 1.5px;
  white-space: nowrap;
  align-items: center;
  margin-right: 30px;
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

@media (width <= 1100px) {
  .content_menu-link {
    padding: 10px 0;
  }
}

@media (width <= 390px) {
  .content_menu-link {
    margin-right: 10px;
    font-size: 13px;
  }
}

.content_menu-link:before {
  content: "";
  width: 100%;
  height: .5px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.content_menu-icon {
  height: 15px;
  margin-right: 5px;
}

.content_menu-icon-li {
  height: 19px;
}

.content_title {
  color: #000;
  background-color: #ffffffd9;
  padding-right: 10px;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.5;
  position: fixed;
  top: 20px;
  left: 50px;
}

@media (width <= 1100px) {
  .content_title {
    text-align: center;
    background: none;
    margin-top: 30px;
    position: static;
  }
}

@media (width <= 400px) {
  .content_title {
    font-size: 27px;
  }
}

.content_face {
  border: 1px dashed #000;
  border-radius: 50%;
  width: 100%;
  max-width: 250px;
  margin: 30px auto 0;
  display: none;
}

@media (width <= 1100px) {
  .content_face {
    display: block;
  }
}

@media (width <= 400px) {
  .content_face {
    max-width: 170px;
  }
}

.content_title-description {
  font-size: 16px;
}

.content_description {
  letter-spacing: 1.05px;
  margin-top: 30px;
  font-size: 16px;
  line-height: 1.87;
}

.content_accent {
  color: #fff;
  background: #1e1e1e;
  padding: 0 4px 1px;
  font-weight: 300;
  line-height: 1.25;
  display: inline-block;
}

.content_emoji {
  margin-right: 3px;
  font-family: Honk, system-ui;
  font-size: 50px;
  line-height: 0;
  display: inline-block;
  position: relative;
  top: 2px;
  left: -3px;
}

.content_accent-secondary {
  margin: 0 14px 10px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
}

.content_list-wrapper {
  margin-top: 55px;
}

.content_list-item {
  letter-spacing: 1.05px;
  border-bottom: 1px dashed #000;
  margin-bottom: 55px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 1.87;
  position: relative;
}

.content_list-item:last-child {
  border-bottom: none;
}

/*# sourceMappingURL=index.5d7bf361.css.map */
