@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

html {
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	position: relative;
	font-family: 'Roboto', sans-serif;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: white;
	}

	&.show::after {
		pointer-events: none;
		opacity: 0;
	}

	&::before {
		pointer-events: none;
		content: '';
		display: block;
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: 100vh;

		@include mobileXl {
			display: none;
		}
	}
}

* {
	-webkit-margin-before: 0em;
	-webkit-margin-after: 0em;
	margin: 0;
	padding: 0;
}

::selection {
	background: yellow;
	color: black;
}

a:focus {
	outline: none;
	background: yellow;
	color: black;
}

body * {
	animation-play-state: paused !important;
}

body.playAnimation * {
	animation-play-state: running !important;
}
